var Protolgol = {};

Protolgol.slider = {
	init: function () {
		$('.upper-block__slider').slick({
			dots: false,
			arrows: true,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 3000
		});

		//modile-slider-instruction page
		var w = $(window).width();
		if (w <= 800) {
			$('.format-slider-modile').slick({
				dots: true,
				arrows: false,
				infinite: true,
				autoplay: true,
				autoplaySpeed: 3000
			});
		}
	}
}

Protolgol.fixed_main_menu = {
	init: function () {
		var w = $(window).width();
		if (w > 800 && $('.main-menu__logo').length) {
			var before_top = $('.main-menu__logo').offset().top;
			var h_top = $('.main-menu__logo').outerHeight();
			var h_main = before_top + h_top;
			var height = $('.main-wrapper').height();
			var line = height;
			var height_menu = $('.main-menu').outerHeight();
			line -= height_menu;
			// line -= 211;
			$(window).scroll(function () {
				var top = $(document).scrollTop();
				if (top > h_main && top < line) {
					$('.main-menu__menu').css('position', 'fixed');
					$('.main-menu__menu').css('bottom', 'initial');
					$('.main-menu__menu').css('top', '0');
					// $('.main-block').css('margin-left','200px');
				}
				if (top < h_main) {
					$('.main-menu__menu').css('position', 'relative');
					$('.main-menu__menu').css('top', 'initial');
					// $('.main-block').css('margin-left','0');
				}
				if (top > line + 40) {
					$('.main-menu__menu').css({
						bottom: '-40px',
						position: 'absolute'
					});
					$('.main-menu__menu').css('top', 'initial');
				}
			});
		}
	}
}

Protolgol.fixed_menu_instruction = {
	init: function () {
		var w = $(window).width();
		if (w > 800) {
			$(window).scroll(function () {
				var top = $(document).scrollTop();
				if (top < 170) $(".instruction__menu").css({
					top: '0',
					position: 'relative',
					width: '100%'
				});
				else $(".instruction__menu").css({
					top: '0',
					position: 'fixed',
					width: 'calc(100% - 200px)'
				});
			});
		}
	}
}

Protolgol.burger = {
	init: function () {
		$('a.burger').on('click', function (event) {
			event.preventDefault();
			if ($(this).hasClass('visible')) {
				$(this).attr('style', '');
				$(this).removeClass('visible');
				$('.main-menu__menu').slideUp(300);
			} else {
				$(this).addClass('visible');
				$(this).css('background', 'url("theme/images/close-burger.png") no-repeat');
				$(this).css('background-size', 'cover');
				$(this).css('width', '30px');
				$(this).css('height', '30px');
				$('.main-menu__menu').slideDown('slow');
			}
		});
	}
}

Protolgol.search = {
	init: function () {
		var w = $(window).width();
		if (w <= 800) {
			$("#search").on('focus', function (event) {
				$('.search-form').addClass('active-hover');
			});
		} else {
			// $('.search-form').hover(
			// 	function () {
			// 		$('#main-search').css('opacity', '1');
			//     },
			//     function () {
			// 		$('#main-search').css('opacity', '0');
			// 		$('#main-search').attr('style','');
			//     }
			//   );

			$("#search").focus(function (event) {
				$(this).next().css('opacity', '1');
				$('.search-form').hover();
			});

			$("#search").on('focus', function (event) {
				// $('.search-form').addClass('active-hover');
				$('#search').css('top', '2');
				$('#search').css('left', '2');
				$('#search').css('height', '44px');
				// $('#search').css('top', '0');
				// $('#search').css('left', '0');
				// $('#search').css('height', '46px');
			});

			$("#search").on('blur', function (event) {
				// $('.search-form').removeClass('active-hover');
				$(this).attr('style', '');
				var value = $(this).val();
				if (value == "") {
					$(this).next().css('opacity', '0');
					$(this).next().attr('style', '');
				}
			});
		}

	}
}

Protolgol.main_menu = {
	init: function () {
		$('.js-menu').on('click', function () {
			$('.js-menu').removeClass('active-menu');
			$(this).addClass('active-menu');
		});
	}
}

Protolgol.instruction_menu = {
	init: function () {
		var w = $(window).width();
		if (w <= 800) {
			$('.js-anchor-m').on('click', function (event) {
				event.preventDefault();
				var id = $(this).attr('href');
				var top = $(id).offset().top;
				$('.js-anchor-m').each(function () {
					$(this).removeClass('active-instruction');
				});
				$(this).addClass('active-instruction');
				$('body,html').animate({
					scrollTop: top
				}, 1500);
			});
		} else {
			if ($('#menu').length) {
				var count;
				$(".js-anchor").each(function () {
					count = $(this).attr('data-number');
				});
				var $menu = $("#menu");
				var $line = $("#line");
				var $indicator = true;
				var $active = $menu.find(".active");
				var default_width = $("#menu").width() / count;
				var sto_percent = $("#menu").width();
				var left_pos = 0;

				//Начальное положение полосы
				$line.css({
					left: 0,
					width: default_width
				});

				$("#menu li").hover(function () {
					if (this === $active.get(0)) return;
					var count;
					$(".js-anchor").each(function () {
						count = $(this).attr('data-number');
					});
					var number = $(this).children().attr('data-number');
					var diff;
					if (number == 1) {
						diff = 0;
					}
					for (var i = 2; i < count + 1; i++) {
						if (number == i) {
							diff = default_width * (i - 1);
						}
					}
					$line.stop().animate({
						width: default_width,
						left: diff
					}, 300);
				}, function () {
					if (this === $active.get(0)) return;
					$line.stop().animate({
						width: default_width,
						left: left_pos
					}, 300);
				});
			}

			var menu_selector = "#menu"; // Переменная должна содержать название класса или идентификатора, обертки нашего меню.

			function onScroll() {
				var scroll_top = $(document).scrollTop();
				$(menu_selector + " a").each(function () {
					var hash = $(this).attr("href");
					var target = $(hash);
					// console.log(1);
					if (target.position().top <= scroll_top && target.position().top + target.outerHeight() > scroll_top) {
						$(menu_selector + " a.active").removeClass("active");
						$(this).addClass("active");
						var number_scroll = $(this).attr('data-number');
						var diff;
						var default_width = $("#menu").width() / count;
						if (number_scroll == 1) {
							diff = 0;
						}
						for (var i = 2; i < count + 1; i++) {
							if (number_scroll == i) {
								diff = default_width * (i - 1);
							}
						}
						$line.stop().animate({
							width: default_width,
							left: diff
						}, 300);
					} else {
						$(this).removeClass("active");
					}
				});
			}

			$(document).on("scroll", onScroll);

			// $("a[href^=#]").click(function(e){
			$('a.js-anchor').on('click', function (event) {
				event.preventDefault();
				$(document).off("scroll");
				$(menu_selector + " a.active").removeClass("active");
				$(this).addClass("active");
				var hash = $(this).attr("href");
				var target = $(hash);
				var count;
				$(".js-anchor").each(function () {
					count = $(this).attr('data-number');
				});
				var diff;
				var number = $(this).attr('data-number');
				if (number == 1) {
					left_pos = 0;
				}
				for (var i = 2; i < count + 1; i++) {
					if (number == i) {
						left_pos = default_width * (i - 1);
					}
					if (number == 3) {
						$('.clip-circle').each(function () {
							var id = $(this).attr('data-id');
							// console.log(id);
							if (id == 1) {
								$(this).attr('src', '/theme/images/method-1.gif');
								$('.method__item__image').first().css('border', '2px solid #6c29c8');
							}
						});
					}
				}
				$line.stop().animate({
					width: default_width,
					left: left_pos
				}, 300);
				$("html, body").animate({
					scrollTop: target.offset().top - 50
				}, 500, function () {
					window.location.hash = hash;
					// $(document).on("scroll", onScroll);
				});
			});

			//Страницы О препарате
			$('.js-about').on('click', function (event) {
				event.preventDefault();
				var id = $(this).attr('href');
				var top = $(id).offset().top;
				$('.js-about').each(function () {
					$(this).removeClass('active-instruction');
				});
				$(this).addClass('active-instruction');
				$('body,html').animate({
					scrollTop: top
				}, 1500);
			});
		}
	}
}

Protolgol.instruction_gif_start = {
	init: function () {
		if ($('#method').length) {
			var check = $('#method').offset().top - 300;
			var checkout = check + $('#method').outerHeight();
			// console.log(check);
			// console.log(checkout);
			$(document).on("scroll", function () {
				// console.log(3);
				var scroll = $(document).scrollTop();
				// console.log(scroll);
				if (scroll >= check) {
					$('.clip-circle').each(function () {
						var id = $(this).attr('data-id');
						// console.log(id);
						if (id == 1) {
							$(this).attr('src', '/theme/images/method-1.gif');
							$('.method__item__image').first().css('border', '2px solid #6c29c8');
						}
					});
				}
				if ((scroll >= check) && (scroll <= checkout)) {
					// $(document).off("scroll");
				}
			});

			$('.method__item').hover(

				function () {
					$('.clip-circle').each(function () {
						var id = $(this).attr('data-id');
						if (id == 1) {
							$(this).attr('src', '/theme/images/method-static-1.png');
							$('.method__item__image').first().attr('style', '');
						}
					});
					var id = $(this).children().children().attr('data-id');
					var id = $(this).children().children().attr('data-id');
					var str = '/theme/images/method-';
					str += id + '.gif';
					$(this).children().children().attr('src', str);

				},

				function () {
					var id = $(this).children().children().attr('data-id');
					var str = '/theme/images/method-static-';
					str += id + '.png';
					$(this).children().children().attr('src', str);
				}
			);
		}
	}
}

Protolgol.answer = {
	init: function () {
		$('.choose-article').on('click', function (event) {
			var currentID = $(this).attr('data-id');
			$('.choose-article').removeClass('active-answer');
			$(this).addClass('active-answer');
			$('.main-faq__content__answer').each(function () {
				var id = $(this).attr('data-id');
				if (id == currentID) {
					$(this).removeClass('hidden-answer');
				} else {
					$(this).addClass('hidden-answer');
				}
			});
			var w = $(window).width();
			if (w < 800) {
				$("body").animate({
					"scrollTop": 0
				}, "slow");
			}
		});
	}
}

Protolgol.changeState = {
	init: function () {
		$('label[for=not-a-robot]').on('click', function () {

			if ($(this).hasClass('pseudoBlockActive')) {
				$(this).removeClass('pseudoBlockActive').addClass('pseudoBlock');
				$('button[type=submit]').removeClass('enabled-button').addClass('disabled-button').attr('disabled', true);
			} else {
				$(this).addClass('pseudoBlockActive').removeClass('pseudoBlock')
				$('button[type=submit]').removeAttr('disabled').removeClass('disabled-button').addClass('enabled-button');
			}
		})
	}
}

Protolgol.showSubUL = {
	init: function () {

		// if($('.showArrow').length){
		// 	$('.showArrow').first().attr('style','url(theme/images/arrow-up.png)');
		// }
		$('.main-block-faq__list > p').on('click', function () {

			if ($(this).hasClass('active')) {
				$(this).next().slideUp('fast');
				$(this).removeClass('active');
				$(this).find('.showArrow').css('background-image', 'url(theme/images/arrow-down.png)');
			} else {
				$(this).next().slideDown('fast');
				$(this).addClass('active');
				$(this).find('.showArrow').css('background-image', 'url(theme/images/arrow-up.png)');
			}
		});
	}
}

function captchaCheck() {
	Protolgol.formValid.validateForm();
}

Protolgol.formValid = {
	nameInput: $("#name"),
	emailInput: $("#email"),
	messageInput: $("#callback-message"),
	processCheckbox: $('#form-process'),

	init: function () {

		var it = this;

		var for_captcha = 0;
		setInterval(function () {
			for_captcha++;
		}, 1000);

		it.nameInput.change(function () {
			it.validateForm();
		});

		it.emailInput.change(function () {
			it.validateForm();
		});

		it.messageInput.change(function () {
			it.validateForm();
		});

		it.processCheckbox.change(function () {
			it.validateForm();
		});

		$('#callback-form').submit(function (event) {
			event.preventDefault();
			var response = grecaptcha.getResponse();
			// console.log(recaptcha);

			if (it.validateForm()) {
				$("#callback-submit").attr("disabled", "disabled").removeClass('enabled-button').addClass('disabled-button');

				var data = {
					name: $("#name").val(),
					email: $("#email").val(),
					message: $("#callback-message").val(),
					_captcha_: it.for_captcha,
					locale: $("input[name=locale]").val()
				};

				$.ajax({
					type: 'POST',
					url: '/mail/feedback',
					data: data
				}).done(function (data) {
					if (data.status) {
						$('.form-wrap').addClass('form-wrap--hidden');
						$('.form-success').addClass('form-success--visible');
					}
				});
			} else {
				it.validateForm();
			}
		});
	},

	captchaCheck: function() {
		it.validateForm();
	},

	validateName: function() {
		var it = this;

		var name = $.trim(it.nameInput.val());
		var expname = /^[а-яА-ЯёЁa-zA-Z\s\-]+$/g;
		var resname = name.search(expname);

		if (resname == -1) {
			it.nameInput.removeClass('ok').addClass('error');
			return false;
		} else {
			it.nameInput.removeClass('error').addClass('ok');
			return true;
		}
	},

	validateEmail: function() {
		var it = this;

		var email = $.trim(it.emailInput.val());
		var expemail = /^[a-z0-9_\-\.]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
		var resemail = email.search(expemail);

		if (resemail == -1) {
			it.emailInput.removeClass('ok').addClass('error');
			return false;
		} else {
			it.emailInput.removeClass('error').addClass('ok');
			return true;
		}
	},

	validateMessage: function() {
		var it = this;

		var text = $.trim(it.messageInput.val());

		if (text == "") {
			it.messageInput.removeClass('ok').addClass('error');
			return false;
		} else {
			it.messageInput.removeClass('error').addClass('ok');
			return true;
		}
	},

	validateForm: function() {
		var it = this;

		if(it.validateName() && it.validateEmail() && it.validateMessage() && grecaptcha.getResponse() !== '' && it.processCheckbox.prop('checked') === true) {
			$('#callback-submit').prop('disabled', false);
			return true;
		} else {
			$('#callback-submit').prop('disabled', true);
			return false;
		}
	}
}

Protolgol.search_form = {
	init: function () {

		var search, statsearch = 0;

		$('#search').change(function () {
			var value = $(this).val();
			if (value == "") {
				$('#form-of-search').addClass('error-search');
				statsearch = 0;
			} else {
				$('#form-of-search').removeClass('error-search');
				statsearch = 1;
			}
		});

		$('#form-of-search').submit(function (event) {
			event.preventDefault();
			if (statsearch == 1) {
				// $("#main-search").attr("disabled", "disabled");
				search = $('#search').val();
				var data = {
					search: search
				};
				$.ajax({
					type: 'POST',
					url: '/search',
					data: data
				}).done(function (data) {
					// console.log('successful');
				});
			} else {
				if (statsearch === 0) {
					$('#form-of-search').addClass('error-search');
				}
			}
		});
	}
}

Protolgol.search_header_form = {
	init: function () {
		var $search = $('._js-search'),
			$nav = $('._js-search-animation');

		$('._js-open-search').on('click', function (e) {
			if (!$search.hasClass('active')) {
				$search.addClass('active');
				$nav.addClass('active');
				e.preventDefault();
				return false;
			}
		});

		$(document).bind('click mouseup touchend', function (e) {
			var el = $search;
			if (!el.is(e.target) &&
				el.has(e.target).length === 0) {
				el.removeClass('active');
				$nav.removeClass('active');
			}
		});
	}
}

Protolgol.mobile_menu = {
	init: function () {
		var $hamburger = $('._js-toggle-menu'),
			$mobileMenu = $('._js-mobile-menu');

		$hamburger.on('click', function (e) {
			$(this).toggleClass('active');
			$mobileMenu.toggleClass('active');
		});
	}
}

Protolgol.sliderAbout = {
	constructor: function () {
		var it = this,
			slider = $('._js-slider'),
			slide = $('._js-slide'),
			nav = $('._js-nav'),
			navItem = $('._js-nav__item'),
			counter = $('._js-counter'),
			sliderActive = $('._js-slider.active'),
			info = $('._js-info'),
			text = $('._js-slider-text'),
			textActive = $('._js-slider-text.active'),
			range = $('._js-list-range');

		this.state = {
			slider: slider,
			slide: slide,
			slides: sliderActive.find('._js-slide').length,
			nav: nav,
			navItem: navItem,
			info: info,
			text: text,
			currentSliderType: sliderActive,
			currentTextType: textActive,
			currentSlide: 0,
			scrollChange: false,
			counter: counter,
			range: range,
			rangeOffset: range.length ? range.offset().top : 0
		};

		if (it.state.slider) {
			it.init();
		}
	},

	init: function () {
		var it = this;

		it.events();
		it.reCalcDots();

		$('._js-slide.active').prevAll().addClass('prev');
		$('._js-slide.active').nextAll().addClass('next');
	},

	stopAllVideo: function (current) {
		$('._js-step-video').each(function () {
			if (this !== current) {
				this.pause();
				this.currentTime = 0;
			}
		});
	},

	events: function () {
		var it = this;

		$(document).ready(function () {
			$(document).on('click', '._js-nav__item', function () {
				var index = it.state.navItem.index(this);

				it.goToSlide(index);
			});

			$(document).on('click', '._js-swap-slider', function () {
				var type = $(this).data('type');

				$('._js-swap-slider').removeClass('active');
				$(this).addClass('active');
				it.goToSlider(type);
			});

			$('._js-scroll').bind('mousewheel DOMMouseScroll', function (e) {
				if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
					if (e.originalEvent.detail > 0 && !it.state.scrollChange && (it.state.currentSlide < it.state.slides - 1)) {
						it.state.scrollChange = true;
						it.goToSlide(it.state.currentSlide + 1);

						if ($('._js-slider.active').data('type') === 'use' && window.innerHeight <= 768) {
							$('._js-scrollable-list').slimScroll({ scrollBy: '80px' });
							if(it.state.currentSlide === it.state.slides - 1) $('._js-list-navigation').addClass('pseudo-remove');
						}
					}
					if (e.originalEvent.detail < 0 && !it.state.scrollChange && it.state.currentSlide > 0) {
						it.state.scrollChange = true;
						it.goToSlide(it.state.currentSlide - 1);

						if ($('._js-slider.active').data('type') === 'use' && window.innerHeight <= 768) {
							$('._js-scrollable-list').slimScroll({ scrollBy: '-80px' });
							if(it.state.currentSlide < 3) $('._js-list-navigation').removeClass('pseudo-remove');
						}
					}
				} else {
					if (e.originalEvent.wheelDelta < -35 && !it.state.scrollChange && (it.state.currentSlide < it.state.slides - 1)) {
						it.state.scrollChange = true;
						it.goToSlide(it.state.currentSlide + 1);

						if ($('._js-slider.active').data('type') === 'use' && window.innerHeight <= 768) {
							$('._js-scrollable-list').slimScroll({ scrollBy: '80px' });
							if(it.state.currentSlide === it.state.slides - 1) $('._js-list-navigation').addClass('pseudo-remove');
						}
					}
					if (e.originalEvent.wheelDelta > 35 && !it.state.scrollChange && it.state.currentSlide > 0) {
						it.state.scrollChange = true;
						it.goToSlide(it.state.currentSlide - 1);

						if ($('._js-slider.active').data('type') === 'use' && window.innerHeight <= 768) {
							$('._js-scrollable-list').slimScroll({ scrollBy: '-80px' });
							if(it.state.currentSlide < 3) $('._js-list-navigation').removeClass('pseudo-remove');
						}
					}
				}

				return false;
			});
		});
	},

	goToSlide: function (number) {
		var it = this,
			currentSlide = $('._js-slide.active'),
			currentSlideIndex = it.state.navItem.index(currentSlide),
			slideWillActive = it.state.currentSliderType.find('._js-slide').eq(number),
			textWillActive = it.state.currentTextType.find('._js-slide').eq(number),
			liItem = $('._js-list-navigation').find('li').eq(number);

		if (currentSlideIndex === number) return false;

		setTimeout(function () {
			it.stopAllVideo(slideWillActive.find('._js-step-video').get(0));
		}, 400);

		slideWillActive.prevAll().addClass('prev').removeClass('active next');
		slideWillActive.addClass('active').removeClass('next prev');
		slideWillActive.nextAll().addClass('next').removeClass('active prev');

		textWillActive.prevAll().addClass('prev').removeClass('active next');
		textWillActive.addClass('active').removeClass('next prev');
		textWillActive.nextAll().addClass('next').removeClass('active prev');

		it.state.navItem.removeClass('active');
		it.state.navItem.eq(number).addClass('active');
		it.state.counter.text((number + 1) + ' из ' + it.state.slides);
		it.state.currentSlide = number;

		if (it.state.currentSliderType.data('type') === 'use') {
			var dif = liItem.offset().top - it.state.rangeOffset;
			// var liIndex = $(liItem).index();
			// if (liIndex === 3) {
			// 	// $('._js-scrollable-list').slimScroll({
			// 	// 	scrollBy: '220px'
			// 	// });
			// } else {
			// 	it.state.range.css({
			// 		top: dif + 'px'
			// 	});
			// }

			it.state.range.css({
				top: dif + 'px'
			});

		}

		if (slideWillActive.find('._js-step-video').length) {
			slideWillActive.find('._js-step-video').get(0).play();
		}

		setTimeout(function () {
			it.state.scrollChange = false;
		}, 1000);
	},

	goToSlider: function (type) {
		var it = this;
		it.state.currentSliderType.removeClass('active');

		if (type === 'use') {
			$('._js-section').addClass('hide-counter');
		} else {
			$('._js-section').removeClass('hide-counter');
		}

		it.state.slider.each(function (index, slider) {
			if ($(slider).data('type') === type) {
				$(slider).addClass('active');
				it.state.currentSliderType = $(slider);
			}
		});

		it.state.info.each(function (index, info) {
			$(info).removeClass('active');
			if ($(info).data('type') === type) {
				$(info).addClass('active');
			}
		});

		it.state.text.each(function (index, text) {
			$(text).removeClass('active');
			if ($(text).data('type') === type) {
				$(text).addClass('active');
			}
		});

		it.reCalcDots();
		it.goToSlide(0);
	},

	reCalcDots: function () {
		var it = this;

		it.state.navItem.remove();

		for (var i = 0; i < this.state.currentSliderType.find('._js-slide').length; i++) {
			it.state.nav.append('<span class="section-nav__item _js-nav__item ' + (i === 0 ? "active" : "") + '"></span>');
		}

		it.state.slides = it.state.currentSliderType.find('._js-slide').length,
			it.state.navItem = $('._js-nav__item');
	}
}

Protolgol.tabs = {
	init: function () {
		var $tabs = $('._js-tabs'),
			$tabsLi = $tabs.find('li'),
			$tabPane = $('._js-pane');

		$tabsLi.on('click', function () {
			var target = $('#' + $(this).data('target')),
				video = target.find('._js-tab-video, ._js-step-video');

			$tabsLi.removeClass('active');
			$(this).addClass('active');

			$tabPane.fadeOut().removeClass('active');
			target.fadeIn().addClass('active');

			if (video.length) {
				video.each(function () {
					this.play();
				});
			}
		});
	}
}

Protolgol.faq = {
	init: function () {
		var $faq = $('._js-toggle-faq');

		$faq.each(function () {
			var $el = $(this);

			if (!$el.parent().hasClass('active')) {
				$el.next().hide();
			}
		});

		$faq.on('click', function () {
			var $this = $(this);

			$faq.each(function () {
				var $el = $(this);

				$el.not($this).next().slideUp();
				$el.not($this).parent().removeClass('active');
			});

			$this.next().slideToggle();
			$this.parent().toggleClass('active');
		});
	}
}

Protolgol.formatSlider = {
	init: function () {
		var $slider = $('._js-slider-format');

		if (!$slider.length) return false;

		$slider.slick({
			dots: false,
			arrows: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 3000,
			slidesToShow: 3,
			responsive: [{
					breakpoint: 1023,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}
}

Protolgol.contentNav = {
	init: function () {
		var $nav = $('._js-content-nav'),
			$area = $('._js-content-area');

		if (!$nav.length) return false;

		$(window).on('load scroll', function () {
			$area.each(function () {
				var id = $(this).attr('id');

				if ($(document).scrollTop() + $(window).height() / 2 > $(this).offset().top && $(document).scrollTop() - $(this).offset().top < $(this).height()) {
					$nav.find('li').removeClass('active');

					$nav.find('li').each(function () {
						if ($(this).data('target') === id) {
							$(this).addClass('active');
						}
					});
				}
			});
		});

		$nav.find('li').on('click', function () {
			var target = $('#' + $(this).data('target')),
				navHeight = $nav.length ? $nav.outerHeight() : 0,
				headerHeight = window.innerWidth >= 768 ? $('.site-header').length ? $('.site-header').outerHeight() : 0 : 0,
				indent = window.innerWidth >= 768 ? 30 : 15;

			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top - navHeight - headerHeight - indent
				}, 700);
				return false;
			}
		});
	}
}

Protolgol.sticky = {
	init: function () {
		var sticky = $('._js-content-sticky');

		if (sticky.length) {
			var topPos = sticky.offset().top - 130;

			$(window).on('load scroll', function () {
				var top = $(document).scrollTop(),
					pip = $('.content').offset().top,
					height = sticky.outerHeight() - 100;

				console.log(top, topPos, pip, height);
				if (top > topPos) {
					// sticky.css({
					// 	top: top - topPos + 70
					// })
					sticky.addClass('fix');
				} else {
					// sticky.css({
					//   top: 70
					// });
					sticky.removeClass('fix');
				}
			});
		}
	}
}

Protolgol.scrollableList = {
	init: function () {
		var scrollList = $('._js-scrollable-list');
		var h = window.innerHeight;
		if (h <= 768) {
			$(scrollList).slimScroll({
				height: '283px',
				// height: '516px',
				color: '#6c29c800',
				size: '1px',
				distance: '-4px',
				railVisible: false,
				railColor: '#6c29c800',
				railOpacity: 0,
				alwaysVisible: false,
			}).bind('slimscroll', function (e, pos) {
				e.preventDefault();
				if (pos === 'bottom') {
					$('._js-list-navigation').addClass('pseudo-remove');

				} else {
					$('._js-list-navigation').removeClass('pseudo-remove');
				}
			});
		}
	}
}

Protolgol.disklemer = {
	init: function () {
		let disklemer = document.querySelector('._js-disklemer');

		if(disklemer) {
			document.addEventListener('DOMContentLoaded', function () {
				const disklemer = getCookie('disklemer');

				if (disklemer) {
					document.querySelector('._js-disklemer').classList.remove('_active');
				} else {
					document.querySelector('._js-disklemer').classList.add('_active');
				}
			});

			document.querySelector('._js-disklemer-close').addEventListener('click', function () {
				disklemer.classList.remove('_active');
				setCookie('disklemer', true, 86400);
			});

			document.querySelector('._js-disklemer-open').addEventListener('click', function () {
				disklemer.classList.add('_active');
			});
		}

		function getCookie(name) {
			var matches = document.cookie.match(new RegExp(
				"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
			));
			return matches ? decodeURIComponent(matches[1]) : undefined;
		}

		function setCookie(name, value, options) {
			options = options || {};

			var expires = options.expires;

			if (typeof expires == "number" && expires) {
				var d = new Date();
				d.setTime(d.getTime() + expires * 1000);
				expires = options.expires = d;
			}
			if (expires && expires.toUTCString) {
				options.expires = expires.toUTCString();
			}

			value = encodeURIComponent(value);

			var updatedCookie = name + "=" + value;

			for (var propName in options) {
				updatedCookie += "; " + propName;
				var propValue = options[propName];
				if (propValue !== true) {
					updatedCookie += "=" + propValue;
				}
			}

			document.cookie = updatedCookie;
		}
	}
}

$(document).ready(function () {

	//Клик по капче
	window.recaptchaCallback = function () {
		$('.g-recaptcha div:first').removeClass('error');
	}

	Protolgol.sliderAbout.constructor();
	Protolgol.disklemer.init();
	Protolgol.tabs.init();
	Protolgol.faq.init();
	Protolgol.search_header_form.init();
	Protolgol.mobile_menu.init();
	Protolgol.formatSlider.init();
	Protolgol.sticky.init();
	Protolgol.contentNav.init();
	Protolgol.instruction_gif_start.init();
	Protolgol.search_form.init();
	Protolgol.fixed_menu_instruction.init();
	Protolgol.burger.init();
	Protolgol.formValid.init();
	Protolgol.slider.init();
	Protolgol.search.init();
	Protolgol.answer.init();
	Protolgol.changeState.init();
	Protolgol.showSubUL.init();
	Protolgol.instruction_menu.init();
	Protolgol.fixed_main_menu.init();
	Protolgol.scrollableList.init();

	if ($('.main-menu__menu').length) {
		var menuPos = $('.main-menu__menu').offset().top;
		var w = $(window).width();
		if (w > 800) {
			var height_for_main__menu = $('.main-block').height() + 85;
			$('.main-menu').css('height', height_for_main__menu);
		}
	}

	//скролл меню

	// $(window).scroll(function() {
	// 	var
	// 	    pipMenu = $('.footer').offset().top,
	// 	    heightMenu = $('.main-menu__menu').outerHeight() + 125,
	// 		top = $(document).scrollTop();

	// 	if (top > menuPos && top < pipMenu - heightMenu) {
	// 	  	$('.main-menu__menu').addClass('fixed').removeClass('relate');
	// 	} else if (top > pipMenu - heightMenu) {
	// 	  	$('.main-menu__menu').addClass('relate').removeClass('fixed');
	// 	} else {
	// 	  	$('.main-menu__menu').removeClass('fixed');
	// 	}
	// });

	if (w > 800) {

		if ($('.main-block-sideblock').length !== 0) {
			var topPos = $('.main-block-sideblock').offset().top;

			$(window).scroll(function () {
				var top = $(document).scrollTop(),
					pip = $('.main-block-content__articles').offset().top,
					height = $('.main-block-sideblock').outerHeight() + 125;

				if (top > topPos && top < pip - height) {
					$('.main-block-sideblock').addClass('fixed').removeClass('relate');
				} else if (top > pip - height) {
					$('.main-block-sideblock').addClass('relate').removeClass('fixed');
				} else {
					$('.main-block-sideblock').removeClass('fixed');
				}
			});
		}

		if ($('.main-block-faq__form').length !== 0) {
			var topPos = $('.main-block-faq__form').offset().top;
			$(window).scroll(function () {
				var top = $(document).scrollTop(),
					pip = $('.footer').offset().top,
					height = $('.main-block-faq__form').outerHeight() + 50;

				if (top > topPos && top < pip - height) {
					$('.main-block-faq__form').addClass('fixed').removeClass('relate');
				} else if (top > pip - height) {
					$('.main-block-faq__form').addClass('relate').removeClass('fixed');
				} else {
					$('.main-block-faq__form').removeClass('fixed');
				}
			});
		}

		if ($('.main-faq__list').length !== 0) {
			var topPos = $('.main-faq__list').offset().top;
			$(window).scroll(function () {
				var top = $(document).scrollTop(),
					pip = $('.footer').offset().top,
					height = $('.main-faq__list').outerHeight() + 125;

				if (top > topPos && top < pip - height) {
					$('.main-faq__list').addClass('fixed').removeClass('relate');
				} else if (top > pip - height) {
					$('.main-faq__list').addClass('relate').removeClass('fixed');
				} else {
					$('.main-faq__list').removeClass('fixed');
				}
			});
		}

	}


})